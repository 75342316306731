// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-dynamic-pages-page-calculator-jsx": () => import("./../../../src/dynamicPages/Page_Calculator.jsx" /* webpackChunkName: "component---src-dynamic-pages-page-calculator-jsx" */),
  "component---src-dynamic-pages-page-home-jsx": () => import("./../../../src/dynamicPages/Page_Home.jsx" /* webpackChunkName: "component---src-dynamic-pages-page-home-jsx" */),
  "component---src-dynamic-pages-page-landing-jsx": () => import("./../../../src/dynamicPages/Page_Landing.jsx" /* webpackChunkName: "component---src-dynamic-pages-page-landing-jsx" */),
  "component---src-dynamic-pages-page-results-jsx": () => import("./../../../src/dynamicPages/Page_Results.jsx" /* webpackChunkName: "component---src-dynamic-pages-page-results-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-how-to-choose-the-right-job-jsx": () => import("./../../../src/pages/articles/how-to-choose-the-right-job.jsx" /* webpackChunkName: "component---src-pages-articles-how-to-choose-the-right-job-jsx" */),
  "component---src-pages-articles-how-to-evaluate-job-stability-jsx": () => import("./../../../src/pages/articles/how-to-evaluate-job-stability.jsx" /* webpackChunkName: "component---src-pages-articles-how-to-evaluate-job-stability-jsx" */)
}

